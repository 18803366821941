import { Routes, Route } from "react-router-dom";
import "./App.css";
//Components
// import Footer from "./components/footer";
import HomeImage from "./components/homeImage";
//Pages
// import MainPage from "./pages/mainPage";

function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          {/* <Route path="/" element={<MainPage />} /> */}
          <Route path="/" element={<HomeImage />} />
        </Routes>
      </div>

      {/* <Footer title={"titulo"} content={"texto de footer"} /> */}
    </div>
  );
}

export default App;
